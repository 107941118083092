<template>
    <div class="yindao">
        <img src="@/assets/img/img_13.png" alt="">
        <img src="@/assets/img/img_14.png" alt="">

        <span class="btn-list sub" @click="submit">开始做题</span>
    </div>
</template>

<script>
    export default {
        methods: {
            submit() {
                this.$router.push({path:'/topic', query: this.$route.query});
            }
        },
    }
</script>

<style lang="less" scoped>
    .yindao{
        min-height: 100vh;
        background: #fff;
        padding-top: 20px;
        .btn-list{
            width: 80%;
            margin: 20px;
            height: 40px;
            background: #E6E8EF;
            border-radius: 24px;
            display: inline-block;
            text-align: center;

            font-size: 18px;
            font-family: MicrosoftYaHei;
            color: #2A3155;
            line-height: 40px;

            margin-right: 12px;
            background: #F9C200;

            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
</style>